// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.takeaway.lk/api/', // 'http://localhost:8000/api/', //
  BASE_URL: 'https://api.takeaway.lk', // 'http://localhost:8000',//
  DEFAULT_LANG: 'en',
  APP_VERSION: '0.1.3', //change app version to force clear cache
  GOOGLE_COUNTRIES: ["LK"] //for google address
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
